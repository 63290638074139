.df-icon {
	fill: $primary;
	stroke-width: 2px;

	&--replay {
		transform: translateX(8%);
	}
}

.df-icon--twitch-word-logo {
	.widget-twitch & {
		width: 88px;
		height: 30px;
		fill: $light;

		.blog-layout--style-1 &,
		.blog-layout--style-2 &,
		.blog-layout--style-3 &,
		.blog-layout--style-4 & {
			@include media-breakpoint-up(lg) {
				@include fluid-value(width, 88);
				@include fluid-value(height, 30);
			}
		}
	}
}

.add-icon {
	position: relative;
	display: block;
	width: 22px;
	height: 22px;
	border-radius: 50%;
	border: 3px solid $light;
	transition: border-color 300ms ease;
	box-sizing: border-box;

	@media (min-width: $xxl-breakpoint) {
		width: 34px;
		height: 34px;
		border-width: 4px;
	}

	&:after,
	&:before {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 6px;
		height: 2px;
		content: "";
		background-color: $primary;
		transition: background-color 300ms ease;

		@media (min-width: $xxl-breakpoint) {
			width: 14px;
			height: 4px;
		}
	}

	&:after {
		transform: translate(-50%, -50%);
	}

	&:before {
		transform: translate(-50%, -50%) rotate(-90deg);
	}

	&:hover {
		border-color: $primary;
	}
}
