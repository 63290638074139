.widget-loop {
	color: $light;

	@include media-breakpoint-down(xs) {
		.blog-layout--style-4 & {
			min-height: 100vw;
		}
	}

	@include media-breakpoint-down(sm) {
		min-height: 50vw;
		width: 100%;
	}

	@include media-breakpoint-up(sm) {
		.blog-layout--style-1 &,
		.blog-layout--style-2 & {
			width: 50%;
		}
	}

	@media (min-width: 576px) and (max-width: 991px) {
		.blog-layout--style-4 & {
			width: 50%;
		}
	}

	@media (min-width: 768px) and (max-width: 991px) {
		.blog-layout--style-3 & {
			width: 50%;
		}
	}

	@include media-breakpoint-up(lg) {
		width: 100%;
	}

	@include media-breakpoint-up(lg) {
		@include fluid-value(font-size, 14);
	}

	&.widget-twitch,
	&.widget-twitter,
	&.widget-facebook {
		a {
			color: $light;
		}
	}

	.widget-twitch,
	.widget-twitter,
	.widget-facebook {
		&__message {
			a {
				font-weight: $font-weight-bold;
			}
		}
	}

	&.widget-facebook {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #224bc0;
	}

	.widget-facebook {
		&__icon {
			flex: 0 1 45%;
			padding-right: 28px;
			font-size: 2.25rem;
			text-align: right;

			@include media-breakpoint-up(lg) {
				@include fluid-value(padding-right, 28);
				@include fluid-value(font-size, 36);
			}
		}

		&__body {
			flex: 0 1 55%;
		}

		&__count {
			margin-bottom: 2px;
			color: $light;

			@include media-breakpoint-up(lg) {
				@include fluid-value(margin-bottom, 2);
				@include fluid-value(font-size, 32);
			}
		}

		&__label {
			letter-spacing: -.03em;
		}
	}

	&.widget-twitter {
		display: flex;
		justify-content: center;
		background-color: #09aafe;

		.blog-layout--style-3 & {
			align-items: center;
		}

		.blog-layout--style-4 & {
			flex-direction: column;
		}
	}

	.widget-twitter {
		&__icon {
			font-size: 2.25rem;
			text-align: center;

			@include media-breakpoint-up(lg) {
				@include fluid-value(font-size, 36);
			}

			.blog-layout--style-3 & {
				flex: 0 0 90px;
				padding-bottom: .5em;

				@include media-breakpoint-up(lg) {
					@include fluid-value(flex-basis, 90);
				}
			}
		}

		&__body {
			.blog-layout--style-3 & {
				flex: 0 1 auto;
			}

			.blog-layout--style-4 & {
				padding: 15px 40px;
				text-align: center;

				@include media-breakpoint-up(md) {
					padding-top: 32px;
					padding-bottom: 32px;
				}

				@include media-breakpoint-up(lg) {
					@include fluid-value(padding-top, 32);
					@include fluid-value(padding-right, 40);
					@include fluid-value(padding-bottom, 32);
					@include fluid-value(padding-left, 40);
				}
			}
		}

		&__message {
			letter-spacing: -.03em;
			color: $light;

			.blog-layout--style-3 &,
			.blog-layout--style-4 & {
				margin-bottom: 20px;

				@include media-breakpoint-up(lg) {
					@include fluid-value(margin-bottom, 20);
				}
			}

			.blog-layout--style-3 & {
				padding-right: 20%;
				line-height: 1.285;
			}

			.blog-layout--style-4 & {
				font-size: 1rem;
				line-height: 1;

				@include media-breakpoint-up(md) {
					font-size: 1.25rem;
				}

				@include media-breakpoint-up(lg) {
					@include fluid-value(font-size, 20);
				}
			}
		}

		&__date {
			letter-spacing: -.03em;

			.blog-layout--style-3 & {
				font-size: .625rem;

				@include media-breakpoint-up(lg) {
					@include fluid-value(font-size, 10);
				}
			}
		}
	}

	&.widget-twitch {
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		background-color: #5d22e4;

		.countdown-counter {
			margin-top: 15px;

			@include media-breakpoint-up(md) {
				margin-top: 32px;
			}

			@include media-breakpoint-up(lg) {
				@include fluid-value(margin-top, 32);
			}

			&__item {
				font-size: 1.625rem;
				font-weight: $font-weight-bold;
				line-height: 1;
				letter-spacing: -.03em;
				color: $warning;

				@include media-breakpoint-up(lg) {
					@include fluid-value(font-size, 26);
				}
			}

			&__item:before,
			&__label {
				color: $light;
			}

			&__label {
				margin-top: 6px;
				font-size: .6875rem;
				font-weight: 500;

				@include media-breakpoint-up(lg) {
					@include fluid-value(margin-top, 6);
					@include fluid-value(font-size, 11);
				}
			}
		}
	}

	.widget-twitch {
		&__icon {
			svg {
				display: block;
				margin: 0 auto;
			}
		}
		&__body {
			padding: 25px 40px 0;

			@include media-breakpoint-up(md) {
				padding-top: 38px;
				padding-bottom: 38px;
			}

			@include media-breakpoint-up(lg) {
				@include fluid-value(padding-top, 38);
				@include fluid-value(padding-bottom, 38);
				@include fluid-value(padding-right, 40);
				@include fluid-value(padding-left, 40);
			}
		}

		&__title {
			margin-bottom: 12px;
			color: $light;

			@include media-breakpoint-up(lg) {
				@include fluid-value(margin-bottom, 12);
				@include fluid-value(font-size, 22);
			}
		}

		&__message {
			line-height: 1.285;
		}
	}
}
