//- Forms
//- ----------------------------------------------

// Search Form
.search-form {

}

.search-form--header {
	.form-control {
		padding-bottom: 0;
		padding-left: 14px;
		padding-right: 14px;
		font-size: 1.25rem;
		font-weight: 700;
		letter-spacing: -.03em;
		text-transform: uppercase;
		color: $light;
		text-align: center;

		@include media-breakpoint-up(md) {
			font-size: 2.875rem;
		}

		@media (min-width: $xxl-breakpoint) {
			font-size: 4rem;
		}

		&::-webkit-input-placeholder { color: $light; transition: all ease 300ms; }
		&::-moz-placeholder          { color: $light; transition: all ease 300ms; }
		&:-ms-input-placeholder      { color: $light; transition: all ease 300ms; }
		&:-moz-placeholder           { color: $light; transition: all ease 300ms; }

		&:hover {
			&::-webkit-input-placeholder { color: rgba($light, .7); }
			&::-moz-placeholder          { color: rgba($light, .7); }
			&:-ms-input-placeholder      { color: rgba($light, .7); }
			&:-moz-placeholder           { color: rgba($light, .7); }
		}

		&:focus {
			&::-webkit-input-placeholder { color: rgba($light, .2); }
			&::-moz-placeholder          { color: rgba($light, .2); }
			&:-ms-input-placeholder      { color: rgba($light, .2); }
			&:-moz-placeholder           { color: rgba($light, .2); }

			border-color: $input-border-color;
		}
	}

	& + span {
		display: inline-block;
		margin-top: 1.5em;
		font-size: .75rem;
		letter-spacing: -.03em;
		line-height: 1.25;
		color: $form-text-color;

		@include media-breakpoint-up(md) {
			margin-top: 2em;
		}

		@media (min-width: $xxl-breakpoint) {
			font-size: 1rem;
		}
	}
}

// Comment Form
.form,
.review-form,
.comment-form {
	@include clearfix;
	.btn,
	button {
		margin-top: 5px;
		float: right;

		@media (min-width: $xxl-breakpoint) {
			margin-top: 10px;
		}
	}

	> .form-group:last-child {
		margin-bottom: 0;
	}

	.form-group--password-forgot + .btn,
	.form-group--password-forgot + button {
		margin-top: 1px;
	}

	.btn + .btn {
		margin-top: 14px;

		@media (min-width: $xxl-breakpoint) {
			margin-top: 18px;
		}
	}
}

// Login Form

.login-form,
.register-form {
	width: 100%;
	max-width: 320px;

	@media (max-width: 1439px) {
		margin: 0 auto;
	}

	@media (min-width: $xxl-breakpoint) {
		max-width: 440px;
	}

	&__info {
		display: inline-block;
		width: 100%;
		margin-top: 30px;
		margin-bottom: 22px;
		font-size: .75rem;
		font-weight: $font-weight-bold;
		line-height: 1.167;
		letter-spacing: -.03em;
		text-transform: uppercase;

		@media (min-width: $xxl-breakpoint) {
			margin-top: 36px;
			margin-bottom: 25px;
			font-size: 1.125rem;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	.h4 + & {
		margin-top: 38px;

		@include media-breakpoint-up(md) {
			margin-top: 66px;
		}
	}
}

.login-form {
	&__button,
	&__twitter-button,
	&__facebook-button {
		color: $light;

		&:hover {
			color: $light;
		}
	}

	&__info {
		@media (min-width: $xxl-breakpoint) {
			margin-top: 56px;
		}
	}

	&__button {
		background-color: $body-color;

		@media (min-width: $xxl-breakpoint) {
			margin-top: 5px;
		}

		&:hover {
			background-color: lighten($body-color, 10%);
		}

		.login-form & {
			margin-top: 0;
		}
	}

	&__twitter-button {
		background-color: #09aafe;

		&:hover {
			background-color: lighten(#09aafe, 10%);
		}
	}

	&__facebook-button {
		background-color: #224bc0;

		&:hover {
			background-color: lighten(#224bc0, 10%);
		}
	}

	.password-reminder {
		font-weight: $font-weight-bold;
		letter-spacing: -.03em;
		text-transform: uppercase;

		@media (min-width: $xxl-breakpoint) {
			margin-top: 7px;
		}

		a {
			color: $info;

			&:hover {
				color: $body-color;
			}
		}
	}
}
