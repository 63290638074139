//- Matches Filter
//- ----------------------------------------------

.matches-filter {
	margin-bottom: 28px;
	float: left;
	text-align: left;
	letter-spacing: -.03em;

	@include media-breakpoint-up(sm) {
		margin-top: -82px;
		float: right;
		text-align: right;
	}

	@include media-breakpoint-up(md) {
		margin-top: -108px;
		margin-bottom: 64px;
	}

	@include media-breakpoint-up(lg) {
		margin-top: -114px;
	}

	@media (min-width: $xxl-breakpoint) {
		margin-top: -139px;
	}

	&__label {
		display: block;
		margin-bottom: 0;
		font-weight: $font-weight-bold;
		text-transform: uppercase;
		color: $secondary;
	}

	.cs-select {
		text-align: left;

		@include media-breakpoint-up(sm) {
			text-align: right;
		}

		.cs-placeholder {
			width: auto;
			display: inline-block;
			padding: 0 20px 0 0;
			font-size: 1rem;
			transition: color 300ms ease;

			&:after {
				top: 6px;
				right: 0;
			}

			&:before {
				top: 4px;
				right: 2px;
			}

			@include media-breakpoint-up(sm) {
				padding: 0 0 0 20px;
				font-size: 1.125rem;

				&:after,
				&:before {
					left: 0;
					right: auto;
				}

				&:after {
					top: 7px;
					left: 0;
				}

				&:before {
					top: 5px;
					left: 2px;
				}
			}

			@media (min-width: $xxl-breakpoint) {
				padding-left: 34px;
				font-size: 1.75rem;

				&:after {
					top: 11px;
					width: 8px;
				}

				&:before {
					top: 8px;
					left: 3px;
					height: 8px;
				}
			}

			&:hover {
				color: $primary;

				&:before {
					opacity: 0;
				}
			}
		}

		.cs-options {
			width: 180px;
			margin-top: 6px;
			box-shadow: 0px 20px 40px 0px rgba(0, 2, 0, .2);

			@media (min-width: $xxl-breakpoint) {
				margin-top: 10px;
				width: 200px;
			}

			ul {
				@media (min-width: $xxl-breakpoint) {
					padding-top: 22px;
					padding-bottom: 22px;
				}
			}

			span {
				padding-top: 7.5px;
				padding-bottom: 7.5px;
				font-size: .625rem;

				@media (min-width: $xxl-breakpoint) {
					padding: 10px 34px;
					font-size: .875rem;
				}
			}

			@include media-breakpoint-up(sm) {
				left: auto;
			}
		}
	}
}
