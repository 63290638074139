.widget-carousel {
	.player {
		position: relative;
		display: flex;
		flex-direction: column;
		padding-top: calc( ( 100vh - #{$header-height-desktop-mobile}) * 0.12 );

		h1, .h1,
		h2, .h2,
		h3, .h3,
		h4, .h4,
		h5, .h5,
		h6, .h6 {
			color: $light;
		}

		&__body,
		&__title,
		&__subtitle {
			text-align: center;
		}

		&__title {
			margin-bottom: 10px;
			font-size: 3.5rem;
			text-transform: none;

			@media (min-width: $xxl-breakpoint) {
				font-size: 5.125rem;
			}
		}

		&__subtitle {
			margin-top: auto;
			margin-bottom: 8px;
			font-weight: 500;

			@media (min-width: $xxl-breakpoint) {
				margin-bottom: 10px;
				font-size: 1.75rem;
			}
		}

		&__body {
			margin-bottom: 60px;

			h5, .h5 {
				font-size: 1.5rem;

				@media (min-width: $xxl-breakpoint) {
					font-size: 2.25rem;
				}
			}
		}

		&__stats {
			@include list-unstyled;

			li {
				display: inline-block;
				margin: 0 23px;
			}
		}

		&__thumbnail {
			position: relative;
			width: 100%;
			height: 62.931%;
			max-height: 448px;
			margin-top: auto;

			@media (min-width: $xxl-breakpoint) {
				max-height: 624px;
			}

			img {
				margin-left: auto;
				margin-right: auto;
				height: 100%;
			}

			.more-info-link {
				position: absolute;
				top: 18%;
				left: calc(74% + 32px);
				font-size: .875rem;
				color: $light;

				@media (min-width: $xxl-breakpoint) {
					font-size: 1.125rem;
				}

				&:hover {
					color: $link-hover-color;
				}

				.add-icon {
					position: absolute;
					top: 50%;
					right: calc(100% + 10px);
					transform: translateY(-50%);
					margin-top: -1px;

					@media (min-width: $xxl-breakpoint) {
						right: calc(100% + 23px);
					}
				}
			}
		}
	}
}
