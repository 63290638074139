//- Staff Members
//- ----------------------------------------------

.content {
	&.staff-layout {
		@include media-breakpoint-up(lg) {
			display: grid;
			grid-auto-flow: column;
			grid-template-rows: 50% 50%;
			grid-auto-columns: calc(100vh - #{$header-height-desktop-mobile});
		}

		@media (min-width: $xxl-breakpoint) {
			grid-auto-columns: calc(100vh - #{$header-height-desktop});
		}
	}
}

.staff-member {
	overflow: hidden;
	background-color: $light;

	@include media-breakpoint-up(sm) {
		display: flex;
	}

	&:nth-child(odd) {
		.staff-member__thumbnail {
			order: 2;
		}
	}

	&__thumbnail {
		@include media-breakpoint-up(sm) {
			width: 50%;
			height: 100%;
		}

		img {
			width: 100%;
		}

		> a {
			position: relative;
			display: block;
			overflow: hidden;
			height: 100%;

			img {
				transition: .4s transform ease;
			}

			&::before {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				content: "";
				background-color: rgba(0,0,0,.6);
				opacity: 0;
				transition: .4s opacity ease;
				z-index: 1;
			}
		}
	}

	&__body {
		position: relative;
		padding: 32px 40px;
		overflow: hidden;

		@include media-breakpoint-up(sm) {
			width: 50%;
		}

		@include media-breakpoint-up(lg) {
			@include fluid-value(padding-top, 32);
			@include fluid-value(padding-right, 40);
			@include fluid-value(padding-bottom, 32);
			@include fluid-value(padding-left, 40);
		}
	}

	&__title {
		padding-right: 20px;

		@include media-breakpoint-up(lg) {
			@include fluid-value(margin-bottom, 18);
			@include fluid-value(padding-right, 20);
			@include fluid-value(font-size, 46);
		}
	}

	&__meta,
	&__position {
		font-weight: $font-weight-bold;
		text-transform: uppercase;
	}

	&__position,
	&__meta span {
		color: $info;
	}

	&__position {
		font-size: 1rem;
		line-height: 2.125;
		letter-spacing: -.06em;

		@include media-breakpoint-up(lg) {
			@include fluid-value(font-size, 16);
		}
	}

	&__meta {
		display: flex;
		justify-content: space-between;
		margin-bottom: 25px;
		padding-top: 2px;
		padding-right: 20px;
		font-size: .75rem;
		line-height: 1;
		letter-spacing: -.03em;

		@include media-breakpoint-up(lg) {
			@include fluid-value(margin-bottom, 25);
			@include fluid-value(padding-top, 2);
			@include fluid-value(padding-right, 20);
			@include fluid-value(font-size, 12);
		}

		span {
			display: block;
			margin-bottom: 3px;
			font-size: .625rem;

			@include media-breakpoint-up(lg) {
				@include fluid-value(margin-bottom, 3);
				@include fluid-value(font-size, 10);
			}
		}
	}

	&__excerpt {
		font-size: .75rem;
		line-height: 1.667;

		@include media-breakpoint-up(lg) {
			@include fluid-value(font-size, 12);
		}
	}

	&:hover {

		.staff-member__title {

			> a {
				color: $secondary;
			}
		}

		.staff-member__thumbnail {

			> a::before {
				opacity: 1;
			}

			img {
				transform: scale(1.025);
			}
		}

	}
}


// Single Staff Member
.staff-member--single {
	overflow: hidden;
	background-color: transparent;

	@include media-breakpoint-up(sm) {
		display: block;
	}

	.staff-member__body {
		padding: 0;

		@include media-breakpoint-up(sm) {
			width: 100%;
		}

		@include media-breakpoint-up(lg) {
			padding: 0;
		}
	}

	.staff-member__position {
		line-height: 2em;
		letter-spacing: -.03em;

		@include media-breakpoint-up(lg) {
			@include fluid-value(font-size, 18);
		}
	}

	.staff-member__meta {
		display: flex;
		justify-content: space-between;
		margin-bottom: 25px;
		padding-right: 20px;
		font-size: .75rem;

		@include media-breakpoint-up(lg) {
			@include fluid-value(margin-bottom, 25);
			@include fluid-value(padding-top, 2);
			@include fluid-value(padding-right, 20);
			@include fluid-value(font-size, 16);
			padding-right: 20%;
		}

		span {
			font-size: .625rem;

			@include media-breakpoint-up(lg) {
				@include fluid-value(font-size, 12);
			}
		}
	}

	.staff-member__content {
		padding-top: 24px;

		h5 {
			font-size: 18px;
		}

		a {
			color: $link-color-invert;

			&:hover {
				color: $link-color-hover-invert;
			}
		}
	}
}
