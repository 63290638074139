//- Counter
//- ----------------------------------------------

.counter {
	&__number,
	&__label {
		font-weight: 700;
		line-height: 1;
		letter-spacing: -.03em;

		.widget-carousel .player__stats & {
			color: $light;
		}
	}

	&__number {
		font-size: 1.625rem;

		@media (min-width: $xxl-breakpoint) {
			font-size: 1.75rem;
		}

		.widget-carousel .player__stats & {
			@media (min-width: $xxl-breakpoint) {
				font-size: 2.5rem;
			}
		}
	}

	&__label {
		margin-top: 3px;
		text-transform: uppercase;
		color: $info;

		@media (min-width: $xxl-breakpoint) {
			margin-top: 5px;
		}

		.widget-carousel .player__stats & {
			@media (min-width: $xxl-breakpoint) {
				font-size: 1.125rem;
			}
		}
	}

	&__icon {
		.df-icon {
			width: 24px;
			height: 24px;
			fill: $success;

			@media (min-width: $xxl-breakpoint) {
				width: 30px;
				height: 30px;
			}
		}

		&--left {
			float: left;
			padding-right: 11px;

			@media (min-width: $xxl-breakpoint) {
				padding-right: 14px;
			}
		}

		&--right {
			float: right;
			padding-left: 11px;

			@media (min-width: $xxl-breakpoint) {
				padding-left: 14px;
			}
		}
	}
}
