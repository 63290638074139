//- Match Stats Widget Table
//- ----------------------------------------------

.match-stats-widget-table {
	text-align: center;

	thead,
	tbody {
		tr {
			display: flex;
		}
	}

	thead {
		display: block;
		padding-right: 24px;
		background-color: $dark;
	}

	tbody {
		background-color: #13151e;
		border-top: 1px solid rgba($secondary, .2);

		width: 100%;

		tr + tr {
			border-top: 1px solid rgba($secondary, .2);
		}
	}

	th, td {
		width: 71px;

		&:first-child {

			min-width: 124px;
			padding-left: 24px;
			padding-right: 24px;
			text-align: left;

			@include media-breakpoint-up(sm) {
				min-width: 344px;
			}

			@include media-breakpoint-up(md) {
				min-width: 524px;
			}

			@include media-breakpoint-up(lg) {
				min-width: 94px;
			}

			@include media-breakpoint-up(xl) {
				min-width: 124px;
			}
		}
	}

	th {
		padding-top: 20px;
		padding-bottom: 20px;
		font-size: .875rem;

		& > span {
			display: block;
			font-size: .625rem;

			.team-cyan {
				color: $msw-cyan-color;
			}

			.team-yellow {
				color: $msw-yellow-color;
			}
		}

		&:first-child {
			padding-top: 24px;
		}
	}

	td {
		display: flex;
		align-items: center;
		color: $secondary;

		&:first-child {
			padding-top: 19px;
			padding-bottom: 17px;
		}

		& + td {
			vertical-align: middle;
			border-left: 1px solid rgba($secondary, .2);
		}

		&:last-child {
			border-right: 1px solid rgba($secondary, .2);
		}
	}

	svg {
		max-width: 70px;
		height: 22px;
		vertical-align: middle;
		fill: $secondary;

		&.team-cyan {
			fill: $msw-cyan-color;
		}

		&.team-yellow {
			fill: $msw-yellow-color;
		}

		&.df-icon--explosion {
			height: 25px;
		}
	}

	&.nano {
		width: 100%;
		height: 266px;

		.nano-content {
			width: 100%;
		}

		.nano-pane {
			top: 71px;
			width: 23px;
			padding: 8px 0px 18px 6px;
			background-color: #13151e;
			opacity: 1;
			border-radius: 0;
			overflow: hidden;
		}

		.nano-slider {
			margin: 0;
			width: 11px;
			border-radius: 0;
			background-color: $body-color;

			&:before {
				position: absolute;
				top: 50%;
				left: 3px;
				width: 5px;
				height: 12px;
				margin-top: -7px;
				content: "";
				background-image: repeating-linear-gradient(to bottom, transparent, transparent 2px, $msw-default-color 2px, $msw-default-color 4px);
			}
		}

		tbody {
			margin-top: 70px;
		}
	}
}
