.footer {
	padding-bottom: 30px;

	@include media-breakpoint-up(md) {
		padding-bottom: 60px;
	}

	@include media-breakpoint-up(lg) {
		padding-bottom: 16.53675vh;
	}
}

.footer--classic {
	background-color: $footer-bg-color;
	color: $footer-txt-color;
	padding: 24px 0;

	a {
		color: $footer-link-color;

		&:hover {
			color: $link-hover-color;
		}
	}

	@include media-breakpoint-up(md) {
		padding: 30px 0;
	}

	@include media-breakpoint-up(lg) {
		padding-top: 30px;
		padding-bottom: 30px;
	}
}
