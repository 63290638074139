//- Widget: Carousel
//- ----------------------------------------------

.widget-carousel {
	&__item {
		display: block;
		position: relative;
		height: calc(100vh - #{$header-height-desktop-mobile});
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;

		@media (min-width: $xxl-breakpoint) {
			height: calc(100vh - #{$header-height-desktop});
		}
	}
}