//- Match Replay
//- ----------------------------------------------

.match-replay {
	width: 100%;
	max-width: 630px;
	margin-left: auto;
	margin-right: auto;
	box-shadow: 0px 40px 60px 0px rgba(0, 2, 0, 0.2);
	height: calc(62.5vw - 30px);

	@include media-breakpoint-up(sm) {
		height: 286px;
	}

	@include media-breakpoint-up(md) {
		height: 326px;
	}

	.matches-tabs & {
		@include media-breakpoint-up(lg) {
			margin-bottom: -14px;
		}
	}

	a {
		position: relative;
		width: 100%;
		height: 100%;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}