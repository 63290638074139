//- Standings Table
//- ----------------------------------------------

.standings-table {

	thead th,
	tbody td {
		text-align: center;
		padding-left: 10px;
		padding-right: 10px;

		&:first-child {
			width: 6%;
			padding-left: 14px;
			padding-right: 0;

			@include media-breakpoint-up(md) {
				padding-left: 24px;
			}
		}

		&:nth-child(2) {
			padding-left: 20px;
			padding-right: 0;

			@include media-breakpoint-up(md) {
				padding-left: 10px;
			}
 		}

		&:first-child,
		&:nth-child(2) {
			text-align: left;
		}

		&:nth-child(7) {
			width: 8%;
		}

		&:last-child {
			width: 8.6%;
			padding-left: 0;
			padding-right: 14px;

			@include media-breakpoint-up(md) {
				padding-right: 24px;
			}
		}
	}

	thead th {
		padding: 2px 10px 11px;
		border: none;

		@media (min-width: $xxl-breakpoint) {
			padding-bottom: 22px;
		}
	}

	tbody { 
		td {
			padding-top: 20px;
			padding-bottom: 20px;
			font-size: .75rem;

			@media (min-width: $xxl-breakpoint) {
				font-size: 1.125rem;
			}
		}
	}

	&__team {
		width: 19.658%;
		min-width: 180px;
	}

	&__wins,
	&__streak--wins {
		color: $matches-standings-wins-color;
	}

	&__losses,
	&__streak--losses {
		color: $matches-standings-losses-color;
	}
}