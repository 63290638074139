.countdown-counter {
	&__item {
		float: left;
		width: 25%;
		position: relative;
		text-align: center;

		& + &:before {
			content: ":";
			display: block;
			position: absolute;
			left: 0;
			top: -3px;
			font-size: 1.5rem;
			line-height: 1.2;
			font-weight: $font-weight-bold;

			@include media-breakpoint-up(xl) {
				@include fluid-value(top, -3);
				@include fluid-value(font-size, 24);
			}
		}
	}

	&__label {
		display: block;
	}
}

